import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { useTranslation } from "react-i18next";
import { CloseBtn, LoginFirst } from "../svg/AllSvg";

const LoginWarn = () => {
    const loginWarnOpen: boolean = useSelector((state: any) => state.data.loginWarnOpen);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setLoginWarnOpen(false))
        }
    };

    return (
        <>
            {loginWarnOpen &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(88,88,88,0.40)] backdrop-blur-[2.5px]" onClick={() => dispatch(dataActions.setLoginWarnOpen(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative md:mx-auto bg-white rounded-[20px] w-full sm:w-[515px] h-full shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]">
                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => { dispatch(dataActions.setLoginWarnOpen(false)); }}>
                                <CloseBtn />
                            </button>
                            <div className="flex flex-col items-center justify-center px-[45px] pt-[40px] pb-[25px]">
                                <LoginFirst />
                                <h1 className="text-xl sm:text-2xl 1xl:text-3xl mt-[35px] text-[#61095A] font-bold font-caudex text-center">{t("home.feedback_login_warn.title")}</h1>
                                <h2 className="text-sm 2xl:text-base text-center mt-[8px] text-[#2D2D2D]">{t("home.feedback_login_warn.desc")}</h2>

                                <div className="mt-[40px] flex gap-[10px] items-center w-full justify-center">
                                    <button className="rounded-[10px] border border-[rgba(97,9,90,0.40)] h-[45px] w-full shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm 2xl:text-base font-bold bg-primary  " onClick={() => { dispatch(dataActions.setLoginOpen(true)); dispatch(dataActions.setLoginWarnOpen(false)); }}>{t("home.feedback_login_warn.login")}</button>
                                    <button className="rounded-[10px] border border-[rgba(97,9,90,0.40)] h-[45px] w-full shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-primary dark:!text-primary text-sm 2xl:text-base font-bold  " onClick={() => { dispatch(dataActions.setRegisterOpen(true)); dispatch(dataActions.setLoginWarnOpen(false)); }}>{t("home.feedback_login_warn.signup")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default LoginWarn;