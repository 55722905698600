/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { dataActions } from "../../shared/store/data-Slice";
import { uiActions } from "../../shared/store/ui-Slice";
import { CloseBtn, UploadIcon, ContactEmail, ContactLocation, ContactCall, GroupClose, Download, RemoveMore, Facebook, Twitter, Youtube, Survay, RightHalfArrowIcon } from "../svg/AllSvg";
import { url } from "../../shared/utils/url";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Formik, ErrorMessage, Field } from "formik";
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload } from 'react-simple-captcha';
import * as yup from "yup";
import InputSecondaryComponent from "../form/InputSecondaryComponent";
import SelectSecondaryComponent from "../form/SelectSecondaryComponent";
import TextAreaComponent from "../form/TextAreaComponent";
import useAxios from "../../shared/hooks/use-axios";

const FeedbackCard = ({ setSuccess, popup }: any) => {
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    const allCities: any = useSelector((state: any) => state.data.allCities);
    const helpLine: any = useSelector((state: any) => state.data.helpLine);
    const color: any = useSelector((state: any) => state.ui.color);
    const complaintCategories: any = useSelector((state: any) => state.data.complaintCategories);
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [files, setFiles] = useState<any>([]);
    const [disable, setDisable] = useState<boolean>(false);
    const [submit, setSubmit] = useState(false);
    const [citiesData, setCitiesData] = useState<any>([]);
    const [cityOpen, setCityOpen] = useState<any>(false);
    const [categoryOpen, setCategoryOpen] = useState<any>(false);
    const [captchaValue, setCaptchaValue] = useState<string>("")
    const [captchaError, setCaptchaError] = useState<string>("")
    const [data, setData] = useState<any>(null)
    const [captchaExpirationTime, setCaptchaExpirationTime] = useState(Date.now() + (5 * 60 * 1000));
    const hicities: any = useSelector((state: any) => state.data.hicities);
    const user: any = useSelector((state: any) => state.auth.user);

    const feedbackObj: any = {
        first_name: !!user?.first_name ? user?.first_name : "",
        last_name: !!user?.last_name ? user?.last_name : "",
        phone: "",
        email: !!user?.email ? user?.email : "",
        feedback: "",
        city: "",
        category: ""
    };

    const feedbackSchema = yup.object().shape({
        first_name: yup.string().required(t("feedback.validation.first_name")),
        last_name: yup.string().required(t("feedback.validation.last_name")),
        feedback: yup.string().required(t("feedback.validation.feedback")),
        city: yup.object().required(t("feedback.validation.city")),
        category: yup.object().required(t("feedback.validation.category")),
        phone: yup.string().matches(/^\d{10}$/, t("feedback.validation.wrong_phone")).required(t("feedback.validation.mobile")),
        email: yup.string().email(t("feedback.validation.wrong_email")).required(t("feedback.validation.email")),
    });

    const simulateChunkUpload = (chunk: any) => {
        // console.log(chunk, "1MB")
        return new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            if (files?.length === 1 || acceptedFiles?.length > 1) {
                return;
            } else {
                setUploadProgress(0);
                setDisable(true)
                setFiles([...files, acceptedFiles[0]]);

                let uploadFile = acceptedFiles[0];
                if (uploadFile) {
                    const chunkSize = 1024 * 1024; // 1MB chunk size
                    const totalChunks = Math.ceil(uploadFile.size / chunkSize);
                    let currentChunk = 0;

                    const uploadNextChunk = () => {
                        const start = currentChunk * chunkSize;
                        const end = Math.min(start + chunkSize, uploadFile.size);
                        const chunk = uploadFile.slice(start, end);

                        simulateChunkUpload(chunk)
                            .then(() => {
                                currentChunk++;

                                if (currentChunk < totalChunks) {
                                    const progress = Math.floor((currentChunk / totalChunks) * 100);
                                    setUploadProgress(progress);

                                    uploadNextChunk();
                                } else {
                                    setUploadProgress(100);
                                    setDisable(false)
                                }
                            })
                            .catch((error) => {
                                console.error('Error uploading chunk', error);
                            });
                    };

                    uploadNextChunk();
                }
            }
        }
    });

    const removeFile = (idx: number) => {
        let arr: any[] = [];
        for (let i = 0; i < files?.length; i++) {
            const el = files[i];

            if (idx !== i) {
                arr.push(el)
            }
        }
        setFiles(arr);
    };

    const thumbs = files.map((file: any, idx: number) => (
        <div key={idx} className="border border-[#D0D0D0] rounded-[10px] h-[40px] flex flex-col items-center w-full relative bg-white shadow-[0px_8px_8px_-4px_rgba(24,39,75,0.08),0px_4px_6px_-4px_rgba(24,39,75,0.12)]">
            <div className='w-full flex items-center justify-between h-full px-[19px]'>
                <h3 className="text-[#4B5563] text-xs sm:text-sm font-normal truncate">{file?.name}</h3>

                {(uploadProgress !== 100) ?
                    <>
                        {(idx + 1 === files?.length) && <div className="flex items-center gap-[10px]">
                            <h1 className="text-primaryLight text-sm font-normal">Uploading {uploadProgress}%</h1>
                            <div className="cursor-pointer" onClick={() => removeFile(idx)}>
                                <GroupClose />
                            </div>
                        </div>}
                    </> :
                    <div className="flex items-center gap-[10px]">
                        <Link aria-label="Download" title="Download" to={URL.createObjectURL(file)} download={URL.createObjectURL(file)} target="_blank" className="cursor-pointer">
                            <Download />
                        </Link>
                        <div aria-label="Remove" title="Remove" className="cursor-pointer" onClick={() => removeFile(idx)}>
                            <RemoveMore />
                        </div>
                    </div>
                }
            </div>
        </div>
    ));

    useEffect(() => {
        loadCaptchaEnginge(4, "#F9F9FF");
        setCaptchaValue("")
    }, [])

    const loadNewCaptcha = () => {
        loadCaptchaEnginge(4, "#F9F9FF");
        setCaptchaValue("")
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            loadCaptchaEnginge(4, "#F9F9FF");
            setCaptchaValue("")
            setCaptchaExpirationTime(Date.now() + (5 * 60 * 1000));
        }, captchaExpirationTime - Date.now());

        return () => clearTimeout(timer);
    }, [captchaExpirationTime]);

    const handleSubmit = async (values: any, { resetForm }: any) => {
        //submit
        if (!!!captchaValue) {
            setCaptchaError("Captcha is required")
            return;
        }

        if (validateCaptcha(captchaValue) !== true) {
            setCaptchaError('Captcha Does Not Match');
            return;
        }

        try {
            if (!isLogin) {
                dispatch(dataActions.setLoginWarnOpen(true))
                dispatch(dataActions.setFeedBack(true))
                return;
            }
            setSubmit(true)
            let obj = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                mobile: values.phone,
                remarks: values.feedback,
                city: values.city.value,
                category: values.category.value
            }

            const res = await useAxios.post(`/${lang + url?.complaintPost}`, obj);

            if (res?.status && res?.status === 201) {
                resetForm({ values: '' });
                setCaptchaValue("")
                setFiles([])
                setSubmit(false)
                if (values.category === 3) {
                    dispatch(dataActions.setComplaintData(res?.data))
                    dispatch(dataActions.setFeedbackSuccess(true))

                } else {
                    setSuccess(true)
                }
                // setTimeout(() => {
                //     setSuccess(false);
                //     dispatch(dataActions.setFeedbackOpen(false));
                // }, 1000);
            }

        } catch (error) {
            setSubmit(false)
            console.error("error while saving profile", error);
        }
    }

    const getContact = () => {
        let arr: any = [];
        let index = 0;
        for (const key in helpLine) {
            arr.push(
                <div className={`flex gap-[8px] ${!!currentCity && (key === currentCity ? "flex" : "hidden")}`} key={index}>
                    <div className="min-w-[19px] pt-[5px]">
                        <ContactCall />
                    </div>
                    <div className="flex flex-col">
                        <h2 className="text-xs sm:text-sm font-medium text-white capitalize">{`${!!hicities && hicities?.[key]} ${t("feedback.emergency_no")}`}</h2>
                        <h2 className="text-xs sm:text-sm text-white font-semibold">{helpLine?.[key]}</h2>
                    </div>

                </div>
            );
            index++;
        }
        return arr;
    };

    const handleLink = (item: any) => {
        dispatch(uiActions.setWebOpen(true));
        dispatch(dataActions.setLink(item));
    }

    useEffect(() => {
        let citiesData: any[] = [];
        for (var i = 0; i < allCities?.length; i++) {
            citiesData.push({ label: allCities[i].name, value: allCities[i].id });
        }
        setCitiesData(citiesData);
    }, [allCities]);

    useEffect(() => {
        dispatch(dataActions.setFeedbackFormData(data))
    }, [data])

    return (
        <div className="bg-white shadow-[0px_14px_64px_-4px_rgba(24,39,75,0.12),0px_8px_22px_-6px_rgba(24,39,75,0.12)] h-full flex w-full lg:max-w-[992px] md:flex-row flex-col">
            <div className="w-full px-[30px] lg:pl-[47px] lg:pr-[85px] py-[36px] h-full relative">
                {popup && <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => dispatch(dataActions.setFeedbackOpen(false))}>
                    <CloseBtn />
                </button>}
                <h1 className="text-primary dark:!text-primary text-3xl font-bold font-caudex">{t("contact_us.title")}</h1>
                <p className="text-[#2D2D2D] dark:!text-[#2D2D2D] text-xs sm:text-sm font-normal text-opacity-[0.5] pt-[6px]">{t("contact_us.subtitle")}</p>

                <Formik
                    initialValues={feedbackObj}
                    validationSchema={feedbackSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,
                    }) => (
                        <Form className="flex flex-col pt-[20px] relative gap-[20px]">
                            <>{setData(values)}</>
                            <div className="flex flex-col gap-[20px]">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[12px]">
                                    <div>
                                        <InputSecondaryComponent title={t("feedback.first_name")} name="first_name" onBlur={handleBlur} onChange={handleChange} value={values?.first_name} />
                                        <ErrorMessage
                                            name="first_name"
                                            component="div"
                                            className="text-red-500 text-xs mb-[-16px]"
                                        />
                                    </div>
                                    <div>
                                        <InputSecondaryComponent title={t("feedback.last_name")} name="last_name" onBlur={handleBlur} onChange={handleChange} value={values?.last_name} />
                                        <ErrorMessage
                                            name="last_name"
                                            component="div"
                                            className="text-red-500 text-xs mb-[-16px]"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <InputSecondaryComponent title={t("feedback.email")} type="email" name="email" onBlur={handleBlur} onChange={handleChange} value={values?.email} />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="text-red-500 text-xs mb-[-16px]"
                                    />
                                </div>
                                <div>
                                    <InputSecondaryComponent title={t("feedback.mobile")} name="phone" onBlur={handleBlur} onChange={handleChange} value={values?.phone} />
                                    <ErrorMessage
                                        name="phone"
                                        component="div"
                                        className="text-red-500 text-xs mb-[-16px]"
                                    />
                                </div>
                                <div>
                                    <label className="text-[#4E4958] text-xs font-normal h-[22px] block" htmlFor="city">{t("home.select_city.title")}</label>
                                    <SelectSecondaryComponent
                                        options={citiesData}
                                        name="city"
                                        placeholder={t("home.select_city.title")}
                                        isSearchable={false}
                                        arrow={true}
                                        onBlur={(e: any) => { setFieldTouched("city", e); setCityOpen(false); }}
                                        onChange={(e: any) => {
                                            setFieldValue("city", e);
                                            setCityOpen(false);
                                        }}
                                        menuIsOpen={cityOpen}
                                        onMenuOpen={() => setCityOpen(true)}
                                        onMenuClose={() => setCityOpen(false)}
                                        value={values?.city}
                                    />
                                    <ErrorMessage
                                        name="city"
                                        component="div"
                                        className="text-red-500 text-xs mb-[-16px]"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="text-[#4E4958] text-xs font-normal h-[22px] block" htmlFor="category">{t("home.select_city.category")}</label>
                                <SelectSecondaryComponent
                                    options={complaintCategories}
                                    name="category"
                                    placeholder={t("home.select_city.category")}
                                    isSearchable={false}
                                    arrow={true}
                                    onBlur={(e: any) => { setFieldTouched("category", e); setCategoryOpen(false); }}
                                    onChange={(e: any) => {
                                        setFieldValue("category", e);
                                        setCategoryOpen(false);
                                    }}
                                    menuIsOpen={categoryOpen}
                                    onMenuOpen={() => setCategoryOpen(true)}
                                    onMenuClose={() => setCategoryOpen(false)}
                                    value={values?.category}
                                />
                                <ErrorMessage
                                    name="category"
                                    component="div"
                                    className="text-red-500 text-xs mb-[-16px]"
                                />
                            </div>
                            <div>
                                <TextAreaComponent title={t("feedback.description")} rows="4" name="feedback" onBlur={handleBlur} onChange={handleChange} value={values?.feedback} />
                                <ErrorMessage
                                    name="feedback"
                                    component="div"
                                    className="text-red-500 text-xs mb-[-16px]"
                                />
                            </div>

                            <p className="text-[#F00] text-[0.625rem] font-bold max-w-[400px]">{t("contact_us.info")}</p>

                            <div className="rounded-[10px] border-2 border-dashed border-[#e1dbff] bg-[#fafafa] flex items-center justify-center gap-[8px] min-h-[50px] cursor-pointer" {...getRootProps()}>
                                <input {...getInputProps()} onClick={(e) => e.stopPropagation()} />
                                {(uploadProgress === 0 || uploadProgress === 100) && (
                                    <div className="cursor-pointer" onClick={open}>
                                        <UploadIcon />
                                    </div>
                                )}
                                <h5 className="text-[#015293] text-sm font-normal">{t("city.lost_found.report_lost_item_form.form.field.drag_file")}</h5>
                            </div>
                            {files?.length > 0 && <aside className='w-full flex flex-col gap-[12px]'>{thumbs}</aside>}

                            <div>
                                <div className="items-center gap-4 grid grid-cols-1 sm:grid-cols-2">
                                    <div>
                                        <div className="flex flex-row items-center gap-4">
                                            <div className="bg-[#F9F9FF] rounded-md w-fit p-[6px]">
                                                <LoadCanvasTemplateNoReload />
                                            </div>
                                            <img src={require("../../assets/images/autorenew.png")} alt="Refresh" onClick={loadNewCaptcha} className="cursor-pointer" />
                                        </div>
                                    </div>
                                    <div>
                                        <Field
                                            type="text"
                                            id="captchaInput"
                                            name="captchaInput"
                                            className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[39px] px-[20px]   text-secondaryText dark:text-grey text-sm font-normal w-full"
                                            value={captchaValue}
                                            onChange={(e: any) => { setCaptchaValue(e.target.value); }}
                                            placeholder={t("captcha.placeholder")}
                                        />
                                    </div>
                                </div>
                                {!!captchaError && <div className="text-red-500 text-xs">{captchaError}</div>}
                            </div>

                            <button className="rounded-[10px] border border-[rgba(97,9,90,0.40)] min-h-[40px] w-full shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold bg-primary mt-[16px] disabled:opacity-[0.3] disabled:cursor-not-allowed   focus:shadow-[0_0_6px_0px_#61095A] hover:shadow-[0_0_6px_0px_#61095A]" type="submit" disabled={isSubmitting}>{t("feedback.submit")}</button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div style={{ background: `linear-gradient(80deg, ${!!currentCity ? `${color[currentCity]?.primary}` : "#5C0054"} -0.21%, ${!!currentCity ? `${color[currentCity]?.secondary}` : "#A71099"} 86.05%)` }} className="w-full md:w-auto md:min-w-[300px] lg:min-w-[382px] flex flex-col justify-between p-[30px] max-h-full gap-[40px]">
                <div className="flex flex-col gap-[25px]">
                    <h2 className="text-base 1xl:text-xl font-bold text-white">{t("contact_us.contact_us")}</h2>

                    <div className="flex flex-col gap-[20px]">
                        {getContact()}


                        {/*<div className="flex items-center gap-[8px]">
                            <div className="min-w-[19px]">
                                <ContactEmail />
                            </div>
                            <Link to="mailto:feedback@lmrcl.com" className="text-white dark:!text-white text-xs sm:text-sm break-all focus:underline hover:underline focus:outline">feedback@lmrcl.com</Link>
                        </div>*/}

                        <div className="flex items-start gap-[8px]">
                            <div className="min-w-[19px]">
                                <ContactLocation />
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <p className="text-white text-xs sm:text-sm font-bold">{t('contact_us.upmrc')}</p>
                                <p className="text-white text-xs sm:text-sm font-normal max-w-[272px]">{t('contact_us.upmrc_address')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-[4px]">
                    <div className="bg-white rounded-[15px] p-[18px] pt-[22px] mb-[15px]">
                        <Survay />

                        <h3 className="text-primary dark:!text-primary text-base font-bold mt-[4px]">{t("home.survey.title")}</h3>
                        <p className="text-xs font-normal text-[#5C5C5C] dark:!text-[#5C5C5C] mt-[4px]">{t("home.survey.desc")}</p>

                        <Link to={`${url?.baseUrl}/${lang}/survey_forms/customer_satisfaction/`} className="flex items-center gap-[4px] mt-[10px] focus:underline hover:underline text-primary dark:!text-primary">
                            <p className="text-primary dark:!text-primary text-xs font-bold">{t("home.survey.form")}</p>
                            <RightHalfArrowIcon className="!w-[12px] !h-[12px]" />
                        </Link>
                    </div>

                    <h5 className="text-white text-xs sm:text-sm font-normal">{t('contact_us.follow')}</h5>
                    <div className="flex items-left gap-[12px] ml-[-8px]">
                        <div className="group">
                            <Link aria-label="Facebook" title='Facebook' to="https://www.facebook.com/officialupmetro/" className="cursor-pointer group-hover:bg-white group-hover:rounded-full w-[30px] h-[30px] flex items-center justify-center  focus:rounded-full focus:shadow-[0_0_6px_0px_#fff]" target="_blank" onClick={(e) => { handleLink("https://www.facebook.com/officialupmetro/"); e.preventDefault(); }}>
                                <Facebook className="w-[15px] h-[15px] 1xl:!w-[20px] 1xl:!h-[20px] 2xl:!w-auto 2xl:!h-auto fill-white group-hover:fill-primary" />
                            </Link>
                        </div>
                        <div aria-label="Twitter" title='Twitter' className="group">
                            <Link to="https://twitter.com/officialupmetro/" className="cursor-pointer group-hover:bg-white group-hover:rounded-full w-[30px] h-[30px] flex items-center justify-center  focus:rounded-full focus:shadow-[0_0_6px_0px_#fff]" target="_blank" onClick={(e) => { handleLink("https://twitter.com/officialupmetro/"); e.preventDefault(); }}>
                                <Twitter className="w-[15px] h-[15px] 1xl:!w-[20px] 1xl:!h-[20px] 2xl:!w-auto 2xl:!h-auto fill-white group-hover:fill-primary" />
                            </Link>
                        </div>
                        <div aria-label="Youtube" title='Youtube' className="group">
                            <Link to="https://www.youtube.com/@OfficialUPMetro" className="cursor-pointer group-hover:bg-white group-hover:rounded-full w-[30px] h-[30px] flex items-center justify-center  focus:rounded-full focus:shadow-[0_0_6px_0px_#fff]" target="_blank" onClick={(e) => { handleLink("https://www.youtube.com/@OfficialUPMetro"); e.preventDefault(); }}>
                                <Youtube className="w-[15px] h-[15px] 1xl:!w-[20px] 1xl:!h-[20px] 2xl:!w-auto 2xl:!h-auto fill-white group-hover:fill-primary" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackCard;