/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from "react-i18next";
import { CloseBtn, CheckBadge } from "../svg/AllSvg";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { url } from "../../shared/utils/url";
import { Form, Formik, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import { useState, useEffect, useRef } from "react";
import InputSecondaryComponent from "../form/InputSecondaryComponent";
import Moment from 'moment';
import useAxios from "../../shared/hooks/use-axios";
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload } from 'react-simple-captcha';

const ParticipateForm = () => {
	const participateOpen: boolean = useSelector((state: any) => state.data.participateOpen);
	const participateData: any = useSelector((state: any) => state.data.participateData);
	const { t } = useTranslation('common');
	const dispatch = useDispatch();
	const lang: string = useSelector((state: any) => state.ui.lang);
	const [submit, setSubmit] = useState(false);
	const [success, setSuccess] = useState(false);
	const [captchaValue, setCaptchaValue] = useState<string>("")
	const [captchaError, setCaptchaError] = useState<string>("")
	const [captchaExpirationTime, setCaptchaExpirationTime] = useState(Date.now() + (5 * 60 * 1000));
	const ref1: any = useRef();

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref1.current && !ref1.current.contains(event.target)) {
				dispatch(dataActions.setParticipateOpen(false))
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref1]);

	const feedbackObj: any = {
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
		feedback: "",
		city: ""
	};

	const feedbackSchema = yup.object().shape({
		first_name: yup.string().required(t("feedback.validation.first_name")),
		last_name: yup.string().required(t("feedback.validation.last_name")),
		phone: yup.string().matches(/^\d{10}$/, t("feedback.validation.wrong_phone")).required(t("feedback.validation.mobile")),
		email: yup.string().email(t("feedback.validation.wrong_email"))
	});

	const handleSubmit = async (values: any) => {
		//submit
		if (!!!captchaValue) {
			setCaptchaError("Captcha is required")
			return;
		}

		if (validateCaptcha(captchaValue) !== true) {
			setCaptchaError('Captcha Does Not Match');
			return;
		}

		try {
			setSubmit(true)
			let obj = {
				first_name: values.first_name,
				last_name: values.last_name,
				email: values.email,
				mobile: values.phone,
				event: participateData?.id
			};

			const res: any = await useAxios.post(`/${lang + url?.eventPost}`, obj);

			if (res?.status && res?.status === 201) {
				setSuccess(true)
				setSubmit(false)
			} else {
				setSubmit(false)
			}
			if (res?.status && res?.status === 400) {
				setSubmit(false)
			} else {
				setSubmit(false)
			}

		} catch (error) {
			setSubmit(false)
			console.error("error while saving profile", error);
		}
	}

	document.onkeydown = function (evt: any) {
		if (evt.keyCode === 27) {
			dispatch(dataActions.setParticipateOpen(false))
		}
	};

	useEffect(() => {
		loadCaptchaEnginge(4, "#F9F9FF");
		setCaptchaValue("")
	}, [])

	const loadNewCaptcha = () => {
		loadCaptchaEnginge(4, "#F9F9FF");
		setCaptchaValue("")
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			loadCaptchaEnginge(4, "#F9F9FF");
			setCaptchaValue("")
			setCaptchaExpirationTime(Date.now() + (5 * 60 * 1000));
		}, captchaExpirationTime - Date.now());

		return () => clearTimeout(timer);
	}, [captchaExpirationTime]);

	return (
		<>
			{participateOpen && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[rgba(203,203,203,0.40)] backdrop-blur-[7.5px]">
				<div className="flex justify-center items-center min-h-screen">
					<div className="relative items-center justify-center my-6 md:mx-auto bg-white rounded-[20px] flex flex-col lg:flex-col w-[320px] sm:w-[600px] md:w-[745px] lg:w-[900px] h-full shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]" ref={ref1}>
						<button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]  focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => dispatch(dataActions.setParticipateOpen(false))}>
							<CloseBtn />
						</button>
						{success ?
							<div className="flex flex-col w-full h-[350px] lg:h-[350px] p-8 justify-center items-center pl-[200px] pr-[200px]">
								<CheckBadge />
								<h1 className="text-2xl mt-[40px] text-[#61095A] font-bold">{t("participate.success.title")}</h1>
								<h2 className="text-m text-center mt-[20px] text-[#6D6D6D]">{t("participate.success.subtitle")}</h2>
							</div>
							:
							<>
								<div className="w-full h-[350px] lg:h-[350px] p-8">
									<img src={url?.baseUrl + participateData?.image?.file} alt={`event${participateData?.id}`} className="w-full h-full object-cover rounded-[20px]" />
								</div>
								<div className="absolute top-0 p-20 text-[#fff]">
									<h1 className="text-xl">{participateData?.title}</h1>
									<h2 className="text-sm mt-4" dangerouslySetInnerHTML={{ __html: participateData?.description }}></h2>
									<div className="flex mt-4">
										<span className="bg-[#F3E8FF] text-[#61095A] px-3 py-1 rounded mr-3 text-[12px]">{Moment(new Date(participateData?.event_start)).format('DD MMMM YYYY')}</span>
										<span className="bg-[#F3E8FF] text-[#61095A] px-3 py-1 rounded text-[12px]">{participateData?.station?.st_name}</span>
									</div>
								</div>
								<div className="flex flex-col w-full lg:w-[calc(100%-300px)] p-[35px] items-center justify-center z-[2]">
									<div className="flex flex-col w-full">
										<Formik
											initialValues={feedbackObj}
											validationSchema={feedbackSchema}
											onSubmit={handleSubmit}
										>
											{({
												values,
												handleChange,
												handleBlur,
												isSubmitting,
											}) => (
												<Form className="flex flex-col pt-[20px] z-[2] relative">
													<div className="flex flex-col gap-[20px]">
														<h2 className="border-b border-gray-300 pb-2 text-primary font-caudex text-l sm:text-l 1xl:text-l font-bold">{t("city.lost_found.report_lost_item_form.form.personal_info")}</h2>
														<div className="grid grid-cols-1 sm:grid-cols-2 gap-[12px]">
															<div>
																<InputSecondaryComponent title={t("participate.form.field.first_name")} name="first_name" onBlur={handleBlur} onChange={handleChange} value={values?.first_name} />
																<ErrorMessage
																	name="first_name"
																	component="div"
																	className="text-red-500 text-xs mb-[-16px]"
																/>
															</div>
															<div>
																<InputSecondaryComponent title={t("participate.form.field.last_name")} name="last_name" onBlur={handleBlur} onChange={handleChange} value={values?.last_name} />
																<ErrorMessage
																	name="last_name"
																	component="div"
																	className="text-red-500 text-xs mb-[-16px]"
																/>
															</div>
														</div>
														<h2 className="border-b border-gray-300 pb-2 text-primary font-caudex text-l sm:text-l 1xl:text-l font-bold">{t("participate.contact_details")}</h2>
														<div className="grid grid-cols-1 sm:grid-cols-2 gap-[12px]">
															<div>
																<InputSecondaryComponent title={t("participate.form.field.email")} type="email" name="email" onBlur={handleBlur} onChange={handleChange} value={values?.email} />
																<ErrorMessage
																	name="email"
																	component="div"
																	className="text-red-500 text-xs mb-[-16px]"
																/>
															</div>
															<div>
																<InputSecondaryComponent title={t("city.lost_found.report_lost_item_form.form.field.phone")} name="phone" onBlur={handleBlur} onChange={handleChange} value={values?.phone} />
																<ErrorMessage
																	name="phone"
																	component="div"
																	className="text-red-500 text-xs mb-[-16px]"
																/>
															</div>
														</div>
														<div>
															<div className="items-center gap-4 grid grid-cols-1 sm:grid-cols-2">
																<div>
																	<div className="flex flex-row items-center gap-4">
																		<div className="bg-[#F9F9FF] rounded-md w-fit p-[6px]">
																			<LoadCanvasTemplateNoReload />
																		</div>
																		<img src={require("../../assets/images/autorenew.png")} alt="Refresh" onClick={loadNewCaptcha} className="cursor-pointer" />
																	</div>
																</div>
																<Field
																	type="text"
																	id="captchaInput"
																	name="captchaInput"
																	className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[39px] px-[20px]   text-secondaryText dark:text-grey text-sm font-normal w-full sm:w-auto sm:max-w-[205px]"
																	value={captchaValue}
																	onChange={(e: any) => { setCaptchaValue(e.target.value); }}
																	placeholder={t("captcha.placeholder")}
																/>
															</div>
															{!!captchaError && <div className="text-red-500 text-xs">{captchaError}</div>}
														</div>
													</div>
													<div className="w-full mt-[16px] flex items-center justify-end">
														<button className="rounded-[10px] border border-[rgba(97,9,90,0.40)] min-h-[40px] w-[50%] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold bg-primary mt-[16px] disabled:opacity-[0.3] disabled:cursor-not-allowed   focus:shadow-[0_0_6px_0px_#61095A] hover:shadow-[0_0_6px_0px_#61095A]" type="submit" disabled={isSubmitting}>
															{t("feedback.submit")}
														</button>
													</div>
												</Form>
											)}
										</Formik>
									</div>
								</div>
							</>
						}
					</div>

				</div>
			</div>
			}
		</>
	);
};

export default ParticipateForm;